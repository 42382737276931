/* main settings */

:root {
  --main: #1d1872;
  --red: #e01515;
}
body {
  background-color: #f7f8f8
}
input {
  border: 0.5px solid rgba(0, 0, 0, 0.7);
}

input:focus {
  outline: none;
  border: 0.5px solid rgba(0, 0, 0, 0.7) !important;
  box-shadow: none !important;
}
.callLink {
  text-decoration: none;
  color: #212529;
}
.hr {
  width: 100%;
  height: 2px;
  margin: 4vh 0px;
  background-color: var(--main);
}
/* popups */
.errorSubBtn {
  border-radius: 5px;
  color: white;
  background-color: rgb(226, 0, 0);
  box-shadow:  0px 0px 10px 0px  rgba(0, 0, 0, 0.1);
  border: none;
  width: 100%;
    height: auto;
    padding: 1em;
  transition: 0.4s;
}

.errorSubBtn:hover {
  background-color: red;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: scale(1.02); */
  transform: translate(0px, -4px);
}

.errorSubBtn:active,
.errorSubBtn:focus {
  outline: none;
  box-shadow: none !important;
}
.critErr{
  text-align: center;
  background-color: white;
  border-radius: 0.5vh;
  border-radius: 1vh;
  color: black;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.critErr > .subBtn {
  color: white;

}

.modal-main {
  transition: 0.4s;
  backdrop-filter: blur(2px);
}

.uiBody{
  text-align: center;
  background-color: #fdfdfd !important;
  padding: 6vh 12vh;
  border-radius: 0.5vh;
  color: black;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.195);
}
.uiBody > .subBtn {
  color: white;
}
@media only screen and (max-width: 550px) {
  .uiBody {
    padding: 2vh 6vh;
  }
}
.iconcolor{
  color: var(--main);
}

.react-confirm-alert-overlay{
  backdrop-filter: blur(2px);
}

.icon-tabler {
  transform: translateY(-1px);
}

.nav-dropdown .dropdown-menu {
  text-align: center;
  min-width: 5rem;
  padding: 0.5rem 0;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 0.2rem;
}

.nav-dropdown .dropdown-menu .dropdown-item.active {
  background-color: var(--main);
}

.nav-dropdown .dropdown-menu .dropdown-item:focus {
  background-color: var(--main);
}
/* navbar */
.nav-dropdown-icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
  -webkit-box-shadow: 0px 0px 36px -11px rgba(66, 68, 90, 0.6);
  -moz-box-shadow: 0px 0px 36px -11px rgba(66, 68, 90, 0.6);
  box-shadow: 0px 0px 36px -11px rgba(66, 68, 90, 0.6);
}
.navbar,
.footer {
  background-color: white;

}
.nav-dropdown-text {
  margin-right: 1rem;
  margin-left: 0.5rem;
}
.Marecki {
  color: rgba(0, 0, 0, 0.55);
  text-decoration: none;
}

.codeFoxes {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  font-weight: bold;
}
.codeFoxes:hover {
  color: #FAA911;
}
.Offcanv {
  color: rgba(0, 0, 0, 0.7);
  transition: 0.2s;
}

.Offcanv:hover {
  color: black;
}

.Offcanv:active,
.Offcanv:focus {
  outline: none;
  border: none !important;
  color: black;
  background-color: transparent;
}

/* footer */

.Copyrights {
  font-size: 15px;
}

.hrfoot {
  border: 1px solid var(--main);
  opacity: 1;
}

/* login pages */

.haer {
  width: 116px;
  background-color: var(--main);
  border: 3px solid var(--main);
  opacity: 1;
}

.orderSubBtn {
  border-radius: 5px;
  color: white;
  background-color: var(--main);
  box-shadow:  0px 0px 10px 0px  rgba(0, 0, 0, 0.1);
  border: none;
  width: 100%;
  height: auto;
  padding: 1em;
  transition: 0.4s;
}

.orderSubBtn:hover {
  background-color: #2B249D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: scale(1.02); */
  transform: translate(0px, -4px);
}

.orderSubBtn:active,
.orderSubBtn:focus {
  background-color: var(--main) !important;
  outline: none;
  box-shadow: none !important;
}
.orderCancelBtn {
  border-radius: 5px;
  background-color: white;
  box-shadow:  0px 0px 10px 0px  rgba(0, 0, 0, 0.1);
  border: none;
  width: 100%;
    height: auto;
    padding: 1em;
  transition: 0.4s;
}

.orderCancelBtn:hover {
  background-color: #dfdfdf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: scale(1.02); */
  transform: translate(0px, -3px);
}

.orderCancelBtn:active,
.orderCancelBtn:focus {
  background-color: #dfdfdf !important;
  outline: none;
  box-shadow: none !important;
}


.subBtn {
  background-color: var(--main);
  border: none;
  width: auto;
  min-width: 50%;
  height: auto;
    padding: 1em;
  transition: width 1s ease-in-out;
}

.subBtn:hover {
  background-color: #2B249D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* transform: scale(1.02); */

}

.subBtn:active,
.subBtn:focus {
  background-color: var(--main) !important;
  outline: none;
  box-shadow: none !important;
}

.alertClose {
  background-color: transparent;
  border: none;
}

.alertClose:hover {
  background-color: transparent;
  border: none;
}

.alert {
  color: azure;
  background-color: var(--main);
  font-size: 13px;
}

.checkbox {
  font-size: 12px;
  color: black;
}

/* Panel */
.logoutBtn {
  background-color: var(--red);
  border: none;
  width: 100%;
  /* width: 16vw; */
  /* max-width: 256px; */
  height: 55px;
  transition: 0.3s;
}
.logoutBtn:hover {
  background-color: #1b2dcf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.logoutBtn:focus {
  box-shadow: none;
  background-color: var(--main);
  border: none;
}


.navBtn {
  background-color: var(--main);
  border: none;
  /* width: 16vw; */
  /* max-width: 256px; */
  height: 55px;
  transition: 0.3s;
}

.navBtn:hover {
  background-color: #1b2dcf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.navBtn:focus {
  box-shadow: none;
  background-color: var(--main);
  border: none;
}

.navBtn:active {
  background-color: #1b2dcf;
  outline: none;
  box-shadow: none !important;
}

.navBtn2 {
  background-color: transparent;
  color: black;
  padding-right: 0px;
  border: none;
  /* width: 16vw; */
  /* max-width: 256px; */
  height: 55px;
  transition: 0.3s;
}

.navBtn2:hover {
  background-color: #1b6fcf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.navBtn2:active {
  background-color: #1b6fcf;
  outline: none;
  box-shadow: none !important;
}

.upperbtn {
  background-color: transparent;
  color: black;
  border: none;
  width: 256px;
  height: 44px;
  text-decoration: none;
  transition: 0.3s;
}

.upperbtn:hover,
.upperbtn:focus {
  background-color: transparent;
  outline: 0px !important;
  border: none;
  box-shadow: none;
  color: black;
}

.upperbtn:active {
  background-color: transparent;
  outline: none;
  box-shadow: none !important;
}
.subBtn2 {
  background-color: var(--main);
  border: none;
  width: 100%;
  height: 55px;
  transition: 0.3s;
}
.subBtn2-canc {
  border: none;
  width: 100%;
  height: 55px;
  transition: 0.3s;
  background-color: #838383;
}
.subBtn2:hover {
  background-color: #1b2dcf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.subBtn2:active,
.subBtn2:focus {
  background-color: var(--main) !important;
  outline: none;
  box-shadow: none !important;
}

.cole {
  padding: 0px;
}

/* order pages */


.navailable {
  background-color: #d2d2d2;
}

.navailable,
.available {
  width: 382px;
  height: 92px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}



.form-control {
  border-radius: 0.2rem !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.form-control:focus, .form-control:active {
  border-radius: 0.3rem !important;
    border: 1px solid rgba(0, 0, 0, 0.35);
}

.divOrderSub {
  z-index: 1 !important;
  height: fit-content;
  top: 20px;
}

.orderSub {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.073);
}

.orderForm {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.073);
  border-radius: 2px;
  background-color: white;
  }
  
  .orderSub {
    background-color: white;
}

.orderLabel {
  border: 1px solid rgb(227, 227, 227);
}

.simplebtn {
  background-color: transparent;
  color: black;
  border: none;
  text-decoration: none;
  transition: 0.3s;
}

.simplebtn:hover,
.simplebtn:focus,
.simplebtn:disabled,
.simplebtn[disabled] {
  background-color: transparent;
  outline: 0px !important;
  border: none;
  box-shadow: none;
  color: black;
}

.simplebtn:active {
  background-color: transparent;
  outline: none;
  box-shadow: none !important;
}

.suggest-error {
  position: absolute;
  margin: 0px;
  padding: 0%;
  color: red;
  font-size: 12px;
  text-align: start;
  padding-left: 2px;
}

.form-control {
  transition: 0.4s;
}

.form-control:focus {
  /* transition: 0.5s;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%; */
}

.form-control-auto {
  transition: 0.5s;
}

.form-control-err-left {
  border: 1.3px solid rgba(227, 0, 0, 0.75) !important;
}

.form-control-err-left:focus {
  border: 1px solid rgba(227, 0, 0, 0.75) !important;
}

.form-control-err-right {}

.form-control-auto:focus {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.autosuggest-highlight {
  padding: 0px;
  background-color: transparent;
  color: var(--main);
  font-weight: bold;
}

.autosuggest-container {}

.autosuggest-container--open {}

.autosuggest-input {}

.autosuggest-input--open {}

.autosuggest-input--focused {}

.autosuggest-suggestions-container {
  position: relative;
}

.autosuggest-suggestions-container--open {
  position: absolute;
    width: 100%;
  /* otwarty container */
    z-index: 4;
      border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 4px solid var(--main);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: white;
  text-align: left;
}

.autosuggest-suggestions-container--open:hover {
  cursor: pointer;
}

.autosuggest-suggestions-list {
  /* lista podpowiedzi */
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.autosuggest-suggestion {
  /* styl do podpunktów */
  padding: 10px;
}

.autosuggest-suggestion--first {}

.autosuggest-suggestion--highlighted {
  /* on hover */
  background-color: rgba(199, 199, 199, 0.2);
}

.autosuggest-section-container {}

.autosuggest-section-container--first {}

.autosuggest-section-title {}

.trips {
  height: 100%
}

.swiper-button-prev {
  position: absolute !important;
  left: 50% !important;
  top: 0 !important;
  transform: rotate(90deg);
  transform-origin: left center;
}
.swiper-button-next {
  position: absolute !important;
  left: 50% !important;
  bottom: 100% !important;
  transform: rotate(90deg);
  transform-origin: left center;

}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.buten {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

.form-check-input:checked {
  background-color: var(--main);
}
.form-switch .form-check-input {
  height: 3vh;
  width: 6vh !important;
  cursor: pointer;
}

.basket-price {
  color: var(--main);
}

.loader-circle {
  background: url(../images/loader.svg) center no-repeat;
  background-size: 100px;
  width: 100px;
  height: 100px;

}
.loader {
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: var(--main);
  background-size: 100px;
  z-index: 4;
  transition: ease-in-out 2s;
  position: fixed;
    overflow: hidden;
  }
  
  .loader-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .loader-anim {
    width: 100%;
    background: url(../images/marecki-load.gif) center no-repeat;
    background-size: 100px;
    transition: ease-in-out 0.5s;
}

.loader-text {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  bottom: 0;
  transform: translate(0px, -35vh);
  position: absolute;
  color: #ffffff;
  transition: ease-in-out 0.5s;

}
.react-tel-input {
  font-family: 'Lexend', sans-serif !important;
}
.swiper-slide {
  height: auto;
  width: auto;
  flex-shrink: 0;
  display: contents;
}

.swiper-slide.swiper-slide-active .card {
  border-right: 8px solid var(--main);
}

.swiper-button-next {
  display: block;
}

.trips-button-next,
.trips-button-prev {
  color: var(--main);
  cursor: pointer;
}

.trips-button-disabled {
  color: #a4a4a4;
  cursor: auto;
}
.form-checkbox {
  transform: scale(1.2);
}

.panel-container {
  min-height: 60vh;
}

.panel-nav {
  border-right: 4px solid var(--main);
}
.logout-btn {
  color: #ff4343;
}
.logout-btn:hover {
  background-color: #ff4343;
  color: white;
}

.bs-example .dropdown {
  position: absolute;
}

.dropdown-item {
  padding: 1vh;
}
.error-label {
  padding: 2px 8px;
  font-weight: 600;
}
@media (max-width: 400px) {
  .swiper-slide{
    margin-bottom: 30px
  }
}
.spinner-border {
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0
}
.option-numeric {
  border: 1px solid rgb(119, 119, 119);
  padding: 0 1vh;
  width: 12vh;
}
.swiper-disabled>.card {
  background-color: #dbdbdb;
  border: none;
}

.mr-hr {
  border: 2px solid var(--main);
  opacity: 1;
  border-radius: 2px;
}

.login-enter {
  opacity: 0;
  transform: scale(0.9);
}

.login-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.login-exit {
  opacity: 1;
}

.login-exit-active {
  opacity: 0;
transition: opacity 300ms, transform 300ms;
}

.input-error-enter {
  opacity: 0;
  transform: scale(0.9);
}

.input-error-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.input-error-exit {
  opacity: 1;
}

.input-error-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.footer-logo {
  width: 80%;
}

.maintance-icon:hover {
  color: #e01515;
  transition: 1s;
}

.maintance-icon {
  color: var(--main);
  transition: 1s;
  cursor: pointer;

}

.maitain-baner {
  color: rgb(41, 41, 41);
  background-color: #ff9c1b;
}
.subBtn2:hover {
  background-color: #251f97;
}

.subBtn2-canc:hover {
  background-color: #5f5f5f;
}

.autosuggest-spiner {
  transition: ease-in-out 1s;
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 1.9rem;
  right: 1rem;
  margin-top: -0.6125rem;
  border-width: 0.125rem;
  z-index: 60;
}
.trip-chng {
  animation: Chng01 0.3s linear;
}

@keyframes Chng01 {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(180deg)
  }
}

.card {
  border: none;
  -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
}
  
.offcanvas-start {
  width: 100%;
  background-color: white;
}

.offcanvas-body {
  max-width: 100% !important;
}

.nav-dropdown>.dropdown-toggle::after {
  color: var(--main);
  border-radius: 50%;
}

.mobile-baner {
  background-color: rgb(17, 183, 17);
}

