.item {
    align-items: center;
    color: #808e9b;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.disabled-page {
    color: #808e9b;
}

.page-active {
    background-color: var(--main);
    border-radius: 40px;
    color: white;
}

.break-me {}

.next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
}

.pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
    padding-top: 4vh;
}

.pagination-page {
    font-weight: 700;
}

.previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
}

.arrow-next,
.arrow-prev {
    color: var(--main);
}
